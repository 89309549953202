import React from "react";
import { Modal, ModalContent } from "@nextui-org/react";
import MailIcon from "../../static/icons/mail.png";
import Form from "./Form";
import { currentLang, LANG } from "../../const/lang/lang";
export default function App({ isOpen, onClose, setConfetti }) {
    function closeModal() {
        onClose();
        setConfetti(true);
    }
    return (
        <>
            <Modal
                backdrop={"blur"}
                isOpen={isOpen}
                isDismissable={false}
                onClose={onClose}
                hideCloseButton={true}
                classNames={{
                    base: "bg-primary ",
                }}
            >
                <ModalContent>
                    <div className="w-full max-w-[419px] rounded-3xl p-0 md:p-10 bg-primary h-[250px] flex items-center">
                        <div className="flex w-full ">
                            <div className="w-[30%] hidden md:flex items-center">
                                <img src={MailIcon} className="w-full" alt="icon" />
                            </div>
                            <div className="w-full text-tertiary px-5 text-center">
                                <h1 className="font-Alice  text-xl mb-2">{LANG.modal_title[currentLang()] }</h1>
                                <p>{LANG.modal_text[currentLang()] }</p>
                                <Form  closeModal={closeModal} />
                            </div>
                        </div>
                    </div>
                </ModalContent>
            </Modal>
        </>
    );
}
