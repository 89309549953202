import BoxBack from "../../static/images/background.png";
import BoxCover from "../../static/images/cover.png";
import ButtonTelegram from "./ButtonTelegram";
import Confetti from "react-dom-confetti";
import Title from "./Title";
import { currentLang, LANG } from "../../const/lang/lang";
export default function Success({ dressSelected, confetti }) {
    const config = {
        angle: 244,
        spread: 360,
        startVelocity: 40,
        elementCount: "192",
        dragFriction: 0.12,
        duration: "4760",
        stagger: 3,
        width: "10px",
        height: "10px",
        perspective: "500px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
    };
    return (
        <div className="px-5 md:px-10 flex justify-center flex-wrap overflow-hidden ">
            <Title text={LANG.restult_title[currentLang()] } />
            <div className="flex justify-center w-full h-[20px] z-50">
                <Confetti active={confetti} config={config} />
            </div>
            <div className="w-[90%] max-w-[265px] h-[400px] md:w-[80%] lg:w-[50%] rounded-lg overflow-hidden relative flex justify-center items-center mt-5">
                <img src={BoxBack} className="h-[100%] w-full absolute" alt="back" />
                <img src={dressSelected.result} className="h-[85%] object-contain w-full absolute" alt="silhouette" />
                <img src={BoxCover} className="h-full w-full absolute" alt="cover" />
            </div>
            <div className="w-full flex justify-center flex-wrap">
                <div className="w-full md:w-[70%]  text-secondary font-bold text-center mt-10 mb-5">
                    <p>{LANG.result_text_1[currentLang()] }</p>
                    <p className="mt-5">{LANG.result_text_2[currentLang()] }</p>
                </div>
                <div className="w-full text-center">
                    <ButtonTelegram />
                </div>
            </div>
        </div>
    );
}
