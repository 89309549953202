import Silhouette from "../../static/images/silhouette.png";
import { Button } from "@nextui-org/react";
import Title from "./Title";
import { DRESSES } from "../../const/data";
import { useState } from "react";
import { currentLang, LANG } from "../../const/lang/lang";

export default function Character({ setStep, dressSelected, setDressSelected, onOpen }) {
    const [listenChangeDress, setListenChangeDress] = useState(false);

    function changeDress(id) {
        setListenChangeDress(false);
        setListenChangeDress(true);
        const getDress = DRESSES.find((dress) => dress.id === id);
        setDressSelected(getDress);
        setTimeout(() => {
            setListenChangeDress(false);
        }, 100);
    }

    return (
        <div className="px-1 md:px10 h-full ">
            <Title text={LANG.fashion_mold[currentLang()] } />
            <div className="w-full md:h-[500px] flex flex-wrap  pb-10">
                <div className=" w-[70%] md:w-[40%] h-[365px] md:h-full  p-2">
                    <div className="bg-[#CDBC9C]/50  w-full  h-full rounded-3xl flex items-center justify-center relative">
                        {!dressSelected && <img src={Silhouette} className="w-full absolute h-full object-contain" alt="solhouette" />}
                        {dressSelected && <img src={dressSelected.shadow} className={`w-full absolute h-full object-contain transition-all ${listenChangeDress ? `scale-125` : ``}`} alt="dress solhouette" />}
                    </div>
                </div>

                <div className=" w-[30%] md:w-[60%]  h-full p-2">
                    <div className="bg-[#CDBC9C]/50 w-full  h-[365px] md:h-full rounded-3xl p-2 md:p-5 gap-2 flex flex-wrap overflow-scroll">
                        {DRESSES.map((dress, index) => (
                            <button key={index} className="w-full md:w-[30%] h-[30%]" onClick={() => changeDress(index + 1)}>
                                <img src={dress.dress} className="object-contain w-full h-full" alt="solhouette" />
                            </button>
                        ))}
                    </div>
                </div>
                <div className="text-center mt-3 w-full">
                    {dressSelected && (
                        <Button
                            color="warning"
                            onClick={() => {
                                setStep(2);
                                onOpen();
                            }}
                            className="text-secondary font-semibold  py-6 px-6 text-xl bg-primary"
                        >
                            {LANG.send[currentLang()]}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
