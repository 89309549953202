import Home from "./pages/home/Home";
import { NextUIProvider } from "@nextui-org/react";
function App() {
	return (
		<>
			<NextUIProvider>
				<Home />
			</NextUIProvider>
		</>
	);
}

export default App;
