import { Button, Input } from "@nextui-org/react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { currentLang, LANG } from "../../const/lang/lang";
export default function Form({ closeModal }) {
    const [input, setInput] = useState({
        email: "",
    });
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const [inputStatus, setInputStatus] = useState("warning");
    async function send() {
        setLoading(true);
        if (!input.email) {
            setInputStatus("danger");
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(input.email)) {
            setInputStatus("danger");
            return;
        }
        setInputStatus("warning");
        
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_USER_ID).then(
            (result) => {
                closeModal();
                setLoading(false);
            },
            (error) => {
                alert("Error: "+error);
                setLoading(false);
            }
        );
    }
    function changeEmail(e) {
        setInput({ ...input, email: e.target.value });
    }
    return (
        <form ref={form}>
            <Input size="sm" type="email" label={LANG.modal_input[currentLang()] } onChange={changeEmail} name="email" className="mt-2" value={input.email} color={inputStatus} />
            <Button isLoading={loading} onPress={send} color="warning" className="mt-2 w-full text-primary">
                {LANG.unlock[currentLang()] } 
            </Button>
        </form>
    );
}
