import World from "../../static/images/world.png";
import Logo from "../../static/brand/logo.png"
import { currentLang, LANG } from "../../const/lang/lang";
export default function Footer() {
    return (
        <footer className="bg-[#0f0f0f] w-full h-[52px] md:h-[180px] flex flex-wrap justify-center text-primary z-10">
            <div className="w-full md:w-[70rem]  relative  md:-mt-10  overflow-hidden">
                <div className="w-full bg-[#0f0f0f] bg-main h-10" />
                <div className="left-0 absolute">
                    <img src={World} alt="world" className="w-[280px]  hidden md:block  h-[280px] rounded-full object-cover -mt-5  -left-5 " />
                </div>
                <img src={Logo} className="absolute hidden md:block w-[90px] mr-5 right-0 mt-0 md:mt-5"  alt="" />
            </div>
            <p className="absolute mt-3 md:mt-28"><span className="font-Alice">Alice</span> App - {LANG.copy[currentLang()] }</p>
        </footer>
    );
}
