import Banner from "./Banner";
import Character from "./Character";
import { useState } from "react";
import Success from "./Success";
import Footer from "./Footer";
import Modal from "./Modal";
import { useDisclosure } from "@nextui-org/react";
import Welcome from "./Welcome";
export default function Home() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [step, setStep] = useState(0);
    const [dressSelected, setDressSelected] = useState(null);
    const [confetti, setConfetti] = useState(false);
    return (
        <>
            {step === 0 && <> 
                <Welcome setStep={setStep} />
            </>}

            {(step === 1 || step === 2) && (
                <div>
                    <Modal isOpen={isOpen} onClose={onClose} setConfetti={setConfetti} />
                    <div className="md:p-10 bg-main p-0 bg-sescondary flex justify-center ">
                        <div className="w-[70rem] flex flex-wrap">
                            <div className="w-[100%] md:w-[40%] h-[200px] md:h-[700px]">
                                <Banner />
                            </div>
                            <div className="w-[100%] md:w-[60%] ">
                                {step === 1 && <Character onOpen={onOpen} setStep={setStep} dressSelected={dressSelected} setDressSelected={setDressSelected} />}
                                {step === 2 && <Success confetti={confetti} setConfetti={setConfetti} dressSelected={dressSelected} />}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </>
    );
}
