import React, { useEffect, useState } from "react";
import Video from "../../static/videos/welcome_background.mp4";
import Logo from "../../static/brand/logo.png";
import { currentLang, LANG } from "../../const/lang/lang";

export default function Welcome({setStep}) {
    const [stage, setStage] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setStage(1);
        }, 1000);
        setTimeout(() => {
            setStage(2);
        }, 3000);
    }, []);

    return (
        <div className="flex justify-center h-screen items-center relative ">
            <video className="w-full h-screen object-cover absolute" src={Video} autoPlay loop muted></video>

            <div className={`absolute w-full h-screen bg-black/50 transition-all ${stage === 1 || stage === 2 ? "opacity-80" : "opacity-0"}`}></div>
            
            <img src={Logo} className={`absolute transition-all ${stage === 1 ? "opacity-1" : "opacity-0"}`} alt="" />
            <div className={`absolute transition-all text-center ${stage === 2 ? "opacity-1" : "opacity-0"}`}>
                <h1 className=" text-5xl font-bold font-Alice mb-10 text-[#F2EACC]">{LANG.hello_welcome[currentLang()] }</h1>
                <p className="text-[#F2EACC] text-2xl mb-5">{LANG.description[currentLang()] } </p>   
                <button onClick={()=>{ 
                    setStep(1);
                }} className="bg-[#F2EACC] text-3xl rounded-xl font-bold  px-10 py-1 cursor-pointer">{LANG.start[currentLang()] }</button>
            </div>
            
        </div>
    );
}
