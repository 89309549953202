import Dress1 from "../static/images/item1_dress.png";
import Dress1Shadow from "../static/images/item1_dress_shadow.png";
import Result1 from "../static/images/item1_result.png";

import Dress2 from "../static/images/item2_dress.png";
import Dress2Shadow from "../static/images/item2_dress_shadow.png";
import Result2 from "../static/images/item2_result.png";

import Dress3 from "../static/images/item3_dress.png";
import Dress3Shadow from "../static/images/item3_dress_shadow.png";
import Result3 from "../static/images/item3_result.png";

import Dress4 from "../static/images/item4_dress.png";
import Dress4Shadow from "../static/images/item4_dress_shadow.png";
import Result4 from "../static/images/item4_result.png";

import Dress5 from "../static/images/item5_dress.png";
import Dress5Shadow from "../static/images/item5_dress_shadow.png";
import Result5 from "../static/images/item5_result.png";

import Dress6 from "../static/images/item6_dress.png";
import Dress6Shadow from "../static/images/item6_dress_shadow.png";
import Result6 from "../static/images/item6_result.png";

import Dress7 from "../static/images/item7_dress.png";
import Dress7Shadow from "../static/images/item7_dress_shadow.png";
import Result7 from "../static/images/item7_result.png";

import Dress8 from "../static/images/item8_dress.png";
import Dress8Shadow from "../static/images/item8_dress_shadow.png";
import Result8 from "../static/images/item8_result.png";

import Dress9 from "../static/images/item9_dress.png";
import Dress9Shadow from "../static/images/item9_dress_shadow.png";
import Result9 from "../static/images/item9_result.png";

export const DRESSES = [
    {
        id: 1,
        dress: Dress1,
        shadow: Dress1Shadow,
        result: Result1,
    },
    {
        id: 2,
        dress: Dress2,
        shadow: Dress2Shadow,
        result: Result2,
    },
    {
        id: 3,
        dress: Dress3,
        shadow: Dress3Shadow,
        result: Result3,
    },
    {
        id: 4,
        dress: Dress4,
        shadow: Dress4Shadow,
        result: Result4,
    },
    {
        id: 5,
        dress: Dress5,
        shadow: Dress5Shadow,
        result: Result5,
    },
    {
        id: 6,
        dress: Dress6,
        shadow: Dress6Shadow,
        result: Result6,
    },
    {
        id: 7,
        dress: Dress7,
        shadow: Dress7Shadow,
        result: Result7,
    },
    {
        id: 8,
        dress: Dress8,
        shadow: Dress8Shadow,
        result: Result8,
    },
    {
        id: 9,
        dress: Dress9,
        shadow: Dress9Shadow,
        result: Result9,
    },
];