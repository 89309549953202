import { currentLang, LANG } from "../../const/lang/lang";
import Telegram from "../../static/icons/telegram.svg";

export default function ButtonTelegram() {
    return (
        <a href="https://t.me/+Ei2XUoPpunNkOGNh" target="_blank" rel="noreferrer">
            <button className="bg-[#30A3E6] text-primary text-xl font-bold py-2 px-5 rounded-xl mb-10 ">
                <div className="flex ">
                    <p className="leading-9">{LANG.button_telegram[currentLang()] }</p>
                    <img src={Telegram} className="w-[40px]" alt="icon telegram" />
                </div>
            </button>
        </a>
    );
}
