export let LANG = {
    hello_welcome: ['Hello & Welcome', 'Bienvenido'],
    description: ['Select an outfit and discover if you fitted well...', 'Selecciona un conjunto y descubre si te queda bien...'],
    start: ['Start', 'Comenzar'],
    fashion_mold: ['Fashion Mold', 'Molde de Moda'],
    copy: ['All Rights Reserved', 'Todos los derechos reservados'],
    modal_title: ['Unlock results...', 'Desbloquear resultados...'],
    modal_text: ['Type your email and discover your results NOW!', '¡Escribe tu correo y descubre tus resultados AHORA!'],
    modal_input: ['Email', 'Correo'],
    unlock: ['Unlock', 'Desbloquear'],
    restult_title: ['Congratulations', 'Felicitaciones'],
    result_text_1: ['You have fit social standards for dressing up. But is this really fitting the way you are??', 'Has cumplido con los estándares sociales para vestirte. ¿Pero realmente esto se ajusta a quién eres?'],
    result_text_2: ['if not, sign up to join thousands that want to be free from standards and be who they really want to be.', 'Si no es así, regístrate para unirte a miles de personas que quieren ser libres de los estándares y ser quien realmente son.'],
    button_telegram: ['Join Telegram Exclusive Chanel', 'Únete al Canal Exclusivo de Telegram'],
    send: ['Send', 'Enviar'],
}
export function currentLang() {
    const nav = navigator.languages;
    if (nav[0].includes('es')) {
        return 1;
    }else{
        return 0;
    }

}