import Logo from "../../static/brand/logo.png";
import Background from "../../static/images/banner.png";

export default function Banner() {
    return (
        <div className="w-full h-full relative flex justify-center items-center rounded-[36px] overflow-hidden">
            <img src={Background} className="w-full h-full object-cover absolute" alt="banner Alice" />
            <div className="bg-secondary/20 w-full h-full absolute" />
            <img src={Logo} alt="Logo Alice" className="z-10  w-[100px] md:w-[150px]" />
        </div>
    );
}
